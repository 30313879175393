import * as React from "react";
import { Box, Card, CardMedia, Typography } from "@mui/material";
import { theme } from "../../../styles/theme";
import { useAuth } from "../../../context/auth";
import { useNavigate } from "react-router-dom";
import { NavbarLogin } from "./content/NavbarLogin";
import LoginBackground from "../../../assets/images/loginBackground.png";
import { RoundedInput } from "../../../components/RoundedInput";
import { Button } from "../../../components/Button";
import { Controller, useForm } from "react-hook-form";

export const Login = () => {
  const { signIn } = useAuth();
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const { userName, password } = data;

    await signIn({ userName, password });
    navigate("/home", { replace: true });
  };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        background: theme.colors.black,
      }}
    >
      <NavbarLogin />
      <Box
        sx={{
          display: "flex",
          width: "100vw",
          height: "calc(100vh - 100px)",
        }}
      >
        <Box //wrapper info
          sx={{
            width: { xs: 0, md: "80%" },
            height: "100%",
            transition: "all 0.5s ease-in-out",
          }}
        >
          <CardMedia
            component="img"
            height="100%"
            width="80%"
            image={LoginBackground}
            alt="login"
            // style={{ objectFit: "cover" }}
          />
        </Box>
        <Box //wrapper login
          sx={{
            pt: 4,
            width: { xs: "100%", md: "20%" },
            height: "100%",
            transition: "all 0.5s ease-in-out",
            overflowY: "auto",
            ml: { md: "5%", lg: "2%" },
            mr: { md: "5%", lg: "10%" },
          }}
        >
          <Card
            sx={{
              display: { xs: "block", md: "none" },
              backgroundColor: theme.colors.silver_dark,
            }}
          >
            <CardMedia
              component="img"
              height="240"
              width="100%"
              image={LoginBackground}
              alt="login"
            />
          </Card>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "30px",
              mx: "auto",
              px: { xs: "10%", sm: "20%", md: "0" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: 14, md: 22 },
                color: theme.colors.yellow_gold,
                fontWeight: "700",
                lineHeight: 1,
              }}
            >
              TV AO VIVO
            </Typography>

            <Typography
              sx={{
                fontSize: { xs: 18, md: 32 },
                color: theme.colors.yellow_gold,
                fontWeight: "900",
                display: { xs: "none", md: "block" },
                lineHeight: 1,
              }}
            >
              STREAMING <br /> É Netline TV
            </Typography>

            <Typography
              sx={{
                fontSize: { xs: 18, md: 32 },
                color: theme.colors.yellow_gold,
                fontWeight: "900",
                display: { xs: "block", md: "none" },
                lineHeight: 1,
              }}
            >
              STREAMING É Netline TV
            </Typography>

            <Typography
              sx={{
                fontSize: { xs: 9, md: 14 },
                color: theme.colors.white,
                fontWeight: "300",
                mt: 1,
              }}
            >
              Uma infraestrutura desenvolvida para viabilizar
              oferta de TV por Assinatura ou Streaming a custos acessíveis, com
              implantação rápida, respeitando os processos legais e comerciais
              do setor com abrangência nacional.
            </Typography>

            <Box // wrapper fields
              sx={{ mt: { xs: 4, md: 10 } }}
            >
              <Box>
                <Controller
                  name="userName"
                  control={control}
                  render={({ field }) => (
                    <RoundedInput
                      name="userName"
                      label="Login"
                      type="text"
                      id="userName"
                      {...field}
                    />
                  )}
                />
              </Box>

              <Box sx={{ mt: 2, mb: 4 }}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <RoundedInput
                      name="password"
                      label="Senha"
                      type="password"
                      id="password"
                      {...field}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box //wrapperLogin
            >
              <Button fullWidth onClick={handleSubmit(onSubmit)}>
                Entrar
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
